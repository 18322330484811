import { RouteObject } from 'react-router-dom';
import { TokenType } from 'src/models/authenticate-result';
import { AppRoutes } from 'src/router/app-routes';
import { appLoader, emptyLoader } from 'src/router/loaders';

export const resultListSuffixes = [
  '19590345',
  '12783994',
  '19346353',
  '09584987',
  '98586021',
  '09840123',
  '95860234',
  '89237501',
  '89273423',
  '45398450',
  '87594301',
  '98693212',
  '12039255',
  '34092123',
  '98420177',
  '84739481',
  '98345982',
  '09123908',
  '12897231',
  '90243024',
  '90483509',
  '39848932',
  '12309234',
  '90234931',
  '04953840',
  '87923483',
  '45609545',
  '70960977',
  '12546523',
  '70890796',
];

export const homeSuffixes = [
  '1387518058',
  '7923559616',
  '3205145528',
  '2800913697',
  '5598176678',
  '2745260150',
  '9435313895',
  '6466899086',
  '9125877930',
  '5444060585',
  '2830720872',
  '0764322983',
  '2949229234',
  '3298036941',
  '8947988189',
  '2949131095',
  '1059854418',
  '4497330086',
  '0270362930',
  '6416725465',
  '7101189579',
  '0601688477',
  '8125526343',
  '8050172282',
  '1328846962',
  '3958726364',
  '1248360830',
  '2618956160',
  '0761199765',
  '8372392882',
  '1769606421',
  '8200615796',
  '0336324690',
  '1410897466',
  '2292620027',
  '4420950074',
  '4957742109',
  '3247505721',
  '6582537170',
  '0366478912',
];

const resultListRoutes: RouteObject[] = [
  {
    path: AppRoutes.AnnouncementResultList({} as any),
    lazy: async () => {
      const { AnnouncementResultList } = await import(
        '../../pages/announcement/AnnouncementResultList/responsive/AnnouncementResultList'
      );
      return { Component: AnnouncementResultList };
    },
    loader: emptyLoader,
  },
  ...resultListSuffixes.map((suffix) => ({
    path: `${AppRoutes.AnnouncementResultList({} as any)}/${suffix}`,
    lazy: async () => {
      const { AnnouncementResultList } = await import(
        '../../pages/announcement/AnnouncementResultList/responsive/AnnouncementResultList'
      );
      return { Component: AnnouncementResultList };
    },
    loader: emptyLoader,
  })),
];
const homeRoutes: RouteObject[] = [
  {
    index: true,
    lazy: async () => {
      const { Home } = await import('../../pages/Home/responsive/Home');
      return { Component: Home };
    },
    loader: emptyLoader,
  },
  ...homeSuffixes.map((suffix) => ({
    path: `${AppRoutes.Home()}/${suffix}`,
    lazy: async () => {
      const { Home } = await import('../../pages/Home/responsive/Home');
      return { Component: Home };
    },
    loader: emptyLoader,
  })),
];

export const sharedRoutes: RouteObject[] = [
  ...homeRoutes,
  ...resultListRoutes,
  {
    path: AppRoutes.UserProfile({} as any),
    lazy: async () => {
      const { UserProfile } = await import('../../pages/user/UserProfile/UserProfile');
      return { Component: UserProfile };
    },
    loader: emptyLoader,
  },
  {
    path: AppRoutes.Contact(),
    lazy: async () => {
      const { Contact } = await import('../../pages/Contact/Contact');
      return { Component: Contact };
    },
    loader: emptyLoader,
  },
  {
    path: AppRoutes.PrivacyPolicy(),
    lazy: async () => {
      const { PrivacyPolicy } = await import('../../pages/PrivacyPolicy/PrivacyPolicy');
      return { Component: PrivacyPolicy };
    },
    loader: emptyLoader,
  },
  {
    path: AppRoutes.TermsOfService(),
    lazy: async () => {
      const { TermsOfService } = await import('../../pages/TermsOfService/TermsOfService');
      return { Component: TermsOfService };
    },
    loader: emptyLoader,
  },
  {
    path: AppRoutes.MyAnnouncements(),
    lazy: async () => {
      const { MyAnnouncements } = await import(
        '../../pages/announcement/MyAnnouncements/responsive/MyAnnouncements'
      );
      return { Component: MyAnnouncements };
    },
    loader: appLoader([TokenType.User, TokenType.Admin, TokenType.AnnouncementsManagement]),
    children: [
      {
        path: AppRoutes.MyAnnouncementsList({} as any),
        lazy: async () => {
          const { MyAnnouncementsList } = await import(
            '../../pages/announcement/MyAnnouncements/sub-pages/MyAnnouncementsList/MyAnnouncementsList'
          );
          return { Component: MyAnnouncementsList };
        },
        loader: appLoader([TokenType.User, TokenType.Admin, TokenType.AnnouncementsManagement]),
      },
      {
        path: AppRoutes.EditAnnouncement({} as any),
        lazy: async () => {
          const { EditAnnouncement } = await import(
            '../../pages/announcement/MyAnnouncements/sub-pages/EditAnnouncement/EditAnnouncement'
          );
          return { Component: EditAnnouncement };
        },
        loader: appLoader([TokenType.User, TokenType.Admin, TokenType.AnnouncementsManagement]),
      },
      {
        path: AppRoutes.NewAnnouncement(),
        lazy: async () => {
          const { NewAnnouncement } = await import(
            '../../pages/announcement/MyAnnouncements/sub-pages/NewAnnouncement/NewAnnouncement'
          );
          return { Component: NewAnnouncement };
        },
        loader: appLoader([TokenType.User, TokenType.Admin, TokenType.AnnouncementsManagement], {
          path: AppRoutes.NewAnnouncementChoice(),
          persistQueryParams: true,
        }),
      },
    ],
  },
];
