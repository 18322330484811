import { RouteObject } from 'react-router-dom';
import { TokenType } from 'src/models/authenticate-result';
import { AppRoutes } from 'src/router/app-routes';
import { appLoader } from 'src/router/loaders';

export const loggedInRoutes: RouteObject[] = [
  {
    path: AppRoutes.MyChats({} as any),
    lazy: async () => {
      const { MyChats } = await import('../../pages/chat/MyChats/responsive/MyChats');
      return { Component: MyChats };
    },
    loader: appLoader([TokenType.Admin, TokenType.User]),
    children: [
      {
        path: AppRoutes.PrivateChat({} as any),
        lazy: async () => {
          const { PrivateChat } = await import('../../pages/chat/PrivateChat/PrivateChat');
          return { Component: PrivateChat };
        },
        loader: appLoader([TokenType.Admin, TokenType.User]),
      },
      {
        path: AppRoutes.GroupChat({} as any),
        lazy: async () => {
          const { GroupChat } = await import('../../pages/chat/GroupChat/GroupChat');
          return { Component: GroupChat };
        },
        loader: appLoader([TokenType.Admin, TokenType.User]),
      },
    ],
  },
  {
    path: AppRoutes.NewGroupChat(),
    lazy: async () => {
      const { NewGroupChat } = await import('../../pages/chat/NewGroupChat/NewGroupChat');
      return { Component: NewGroupChat };
    },
    loader: appLoader([TokenType.Admin, TokenType.User]),
  },
  {
    path: AppRoutes.ObservedSearches(),
    lazy: async () => {
      const { ObservedSearches } = await import(
        '../../pages/account/ObservedSearches/ObservedSearches'
      );
      return { Component: ObservedSearches };
    },
    loader: appLoader([TokenType.Admin, TokenType.User]),
  },
  {
    path: AppRoutes.Notifications(),
    lazy: async () => {
      const { Notifications } = await import('../../pages/user/Notifications/Notifications');
      return { Component: Notifications };
    },
    loader: appLoader([TokenType.Admin, TokenType.User]),
  },
  {
    path: AppRoutes.Settings(),
    lazy: async () => {
      const { Settings } = await import('../../pages/account/Settings/responsive/Settings');
      return { Component: Settings };
    },
    loader: appLoader([TokenType.Admin, TokenType.User]),
    children: [
      {
        path: AppRoutes.SettingsChangeData(),
        lazy: async () => {
          const { SettingsChangeData } = await import(
            '../../pages/account/Settings/sub-pages/SettingsChangeData'
          );
          return { Component: SettingsChangeData };
        },
        loader: appLoader([TokenType.Admin, TokenType.User]),
      },
      {
        path: AppRoutes.SettingsChangePassword(),
        lazy: async () => {
          const { SettingsChangePassword } = await import(
            '../../pages/account/Settings/sub-pages/SettingsChangePassword'
          );
          return { Component: SettingsChangePassword };
        },
        loader: appLoader([TokenType.Admin, TokenType.User]),
      },
      {
        path: AppRoutes.SettingsConnectedAccounts(),
        lazy: async () => {
          const { SettingsConnectedAccounts } = await import(
            '../../pages/account/Settings/sub-pages/SettingsConnectedAccounts'
          );
          return { Component: SettingsConnectedAccounts };
        },
        loader: appLoader([TokenType.Admin, TokenType.User]),
      },
      {
        path: AppRoutes.SettingsChangeEmail(),
        lazy: async () => {
          const { SettingsChangeEmail } = await import(
            '../../pages/account/Settings/sub-pages/SettingsChangeEmail'
          );
          return { Component: SettingsChangeEmail };
        },
        loader: appLoader([TokenType.Admin, TokenType.User]),
      },
      {
        path: AppRoutes.SettingsPrivacy(),
        lazy: async () => {
          const { SettingsPrivacy } = await import(
            '../../pages/account/Settings/sub-pages/SettingsPrivacy'
          );
          return { Component: SettingsPrivacy };
        },
        loader: appLoader([TokenType.Admin, TokenType.User]),
      },
      {
        path: AppRoutes.SettingsEmailNotifications(),
        lazy: async () => {
          const { SettingsEmailNotifications } = await import(
            '../../pages/account/Settings/sub-pages/SettingsEmailNotifications'
          );
          return { Component: SettingsEmailNotifications };
        },
        loader: appLoader([TokenType.Admin, TokenType.User]),
      },
    ],
  },
];
