import { LoaderFunctionArgs, redirect, replace } from 'react-router-dom';
import { queryClient } from 'src/config/query-config';
import { AuthenticateResult, TokenType } from 'src/models/authenticate-result';
import { tryAuthenticateQueryFn, tryAuthenticateQueryKey } from 'src/queries/auth-queries';
import { AppRoutes } from 'src/router/app-routes';

const loadAuthenticateResult = async () => {
  // undefined when user is not yet fetched, null when user is not logged in
  let result: AuthenticateResult | null | undefined =
    queryClient.getQueryData(tryAuthenticateQueryKey);
  if (result === undefined) {
    try {
      result = await queryClient.fetchQuery(tryAuthenticateQueryKey, tryAuthenticateQueryFn);
    } catch {
      console.log('error loading authenticate result');
    }
  }
  return result as AuthenticateResult | null;
};

export const appLoader = (
  allowedTypes: (TokenType | 'anonymous')[],
  onFailRedirectTo?: { path: string; persistQueryParams: boolean }
) => {
  return async ({ request }: LoaderFunctionArgs<unknown>) => {
    const result = await loadAuthenticateResult();
    if (!result && allowedTypes.includes('anonymous')) {
      return null;
    }
    if (result && allowedTypes.includes(result.type)) {
      return null;
    }

    if (onFailRedirectTo) {
      if (onFailRedirectTo.persistQueryParams) {
        const [, query] = request.url.split('?');
        return redirect(`${onFailRedirectTo.path}${query ? `?${query}` : ''}`);
      } else {
        return redirect(onFailRedirectTo.path);
      }
    }

    return replace(AppRoutes.Login({ redirectUrl: location.pathname + location.search }));
  };
};
export const emptyLoader = async (args: LoaderFunctionArgs<unknown>) => {
  await loadAuthenticateResult();
  return null;
};
