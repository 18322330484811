import { AnnouncementEmail } from './announcement-email';
import { ExtendedUser } from './user';

export enum TokenType {
  User,
  Admin,
  AnnouncementsManagement,
}
export type AuthenticateResult =
  | {
      type: TokenType.AnnouncementsManagement;
      entity: AnnouncementEmail;
    }
  | { type: TokenType.User | TokenType.Admin; entity: ExtendedUser };
