import {
  ButtonProps as MuiButtonProps,
  ButtonTypeMap,
  default as MuiButton
} from '@mui/material/Button';
import { ForwardedRef } from 'react';
import { ButtonOrLinkProps, useButtonOrLink } from 'src/hooks/useButtonOrLink';
import { typedForwardRef } from 'src/utils/types/forward-ref';
import { Tooltip } from './Tooltip';

export type ButtonProps<D extends React.ElementType = ButtonTypeMap['defaultComponent']> =
  MuiButtonProps<D, { component?: D }> &
    ButtonOrLinkProps & {
      tooltip?: string;
    };

export const ButtonInner = <D extends React.ElementType = ButtonTypeMap['defaultComponent']>(
  { variant = 'contained', sx, buttonOrLink, tooltip, ...props }: ButtonProps<D>,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const rest = useButtonOrLink(buttonOrLink);

  const button = (
    <MuiButton
      variant={variant}
      sx={{
        textTransform: 'none',
        borderRadius: 25,
        fontSize: variant === 'text' ? 13 : undefined,
        ...sx,
      }}
      {...props}
      {...rest}
      ref={ref}
    ></MuiButton>
  );

  return tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button;
};

export const Button = typedForwardRef(ButtonInner);
