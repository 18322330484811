import { RouteObject } from 'react-router-dom';
import { TokenType } from 'src/models/authenticate-result';
import { AppRoutes } from 'src/router/app-routes';
import { appLoader } from 'src/router/loaders';

// TODO remove
export const newAnnouncementSuffixes = [
  '23142112',
  '82913234',
  '34817293',
  '73832912',
  '73349452',
  '73839224',
  '85932912',
  '73802934',
];

export const notLoggedInRoutes: RouteObject[] = [
  {
    path: AppRoutes.Login(),
    lazy: async () => {
      const { Login } = await import('../../pages/account/Login/Login');
      return { Component: Login };
    },
    loader: appLoader([TokenType.AnnouncementsManagement, 'anonymous'], {
      path: AppRoutes.Home(),
      persistQueryParams: false,
    }),
  },
  {
    path: AppRoutes.Register(),
    lazy: async () => {
      const { Register } = await import('../../pages/account/Register/Register');
      return { Component: Register };
    },
    loader: appLoader([TokenType.AnnouncementsManagement, 'anonymous']),
    children: [
      {
        path: AppRoutes.RegisterRegular(),
        lazy: async () => {
          const { RegisterRegular } = await import(
            '../../pages/account/Register/sub-pages/RegisterRegular'
          );
          return { Component: RegisterRegular };
        },
        loader: appLoader([TokenType.AnnouncementsManagement, 'anonymous']),
      },
      {
        path: AppRoutes.RegisterExternal(),
        lazy: async () => {
          const { RegisterExternal } = await import(
            '../../pages/account/Register/sub-pages/RegisterExternal'
          );
          return { Component: RegisterExternal };
        },
        loader: appLoader([TokenType.AnnouncementsManagement, 'anonymous']),
      },
    ],
  },
  {
    path: AppRoutes.ForgotPassword(),
    lazy: async () => {
      const { ForgotPassword } = await import('../../pages/account/ForgotPassword/ForgotPassword');
      return { Component: ForgotPassword };
    },
    loader: appLoader([TokenType.AnnouncementsManagement, 'anonymous']),
  },
  {
    path: AppRoutes.ResetPassword({} as any),
    lazy: async () => {
      const { ResetPassword } = await import('../../pages/account/ResetPassword/ResetPassword');
      return { Component: ResetPassword };
    },
    loader: appLoader([TokenType.AnnouncementsManagement, 'anonymous']),
  },
  {
    path: AppRoutes.NewAnnouncementPublic(),
    lazy: async () => {
      const { NewAnnouncementPublic } = await import(
        '../../pages/announcement/NewAnnouncementPublic/NewAnnouncementPublic'
      );
      return { Component: NewAnnouncementPublic };
    },
    loader: appLoader([TokenType.AnnouncementsManagement, 'anonymous']),
  },
  {
    path: AppRoutes.NewAnnouncementChoice(),
    lazy: async () => {
      const { NewAnnouncementChoice } = await import(
        '../../pages/announcement/NewAnnouncementChoice/NewAnnouncementChoice'
      );
      return { Component: NewAnnouncementChoice };
    },
    loader: appLoader([TokenType.AnnouncementsManagement, 'anonymous']),
  },
];
