import { RouteObject } from 'react-router-dom';
import { TokenType } from 'src/models/authenticate-result';
import { AppRoutes } from 'src/router/app-routes';
import { appLoader } from 'src/router/loaders';

export const adminRoutes: RouteObject[] = [
  {
    path: AppRoutes.AdminPanel(),
    lazy: async () => {
      const { AdminPanel } = await import('../../pages/account/AdminPanel/responsive/AdminPanel');
      return { Component: AdminPanel };
    },
    loader: appLoader([TokenType.Admin]),
    children: [
      {
        path: AppRoutes.AdminPanelPendingAnnouncements(),
        lazy: async () => {
          const { AdminPanelPendingAnnouncements } = await import(
            '../../pages/account/AdminPanel/sub-pages/AdminPanelPendingAnnouncements'
          );
          return { Component: AdminPanelPendingAnnouncements };
        },
        loader: appLoader([TokenType.Admin]),
      },
      {
        path: AppRoutes.AdminPanelFeedback(),
        lazy: async () => {
          const { AdminPanelFeedback } = await import(
            '../../pages/account/AdminPanel/sub-pages/AdminPanelFeedback'
          );
          return { Component: AdminPanelFeedback };
        },
        loader: appLoader([TokenType.Admin]),
      },
      {
        path: AppRoutes.AdminPanelViolationReports(),
        lazy: async () => {
          const { AdminPanelViolationReports } = await import(
            '../../pages/account/AdminPanel/sub-pages/AdminPanelViolationReports'
          );
          return { Component: AdminPanelViolationReports };
        },
        loader: appLoader([TokenType.Admin]),
      },
      {
        path: AppRoutes.AdminPanelErrorLogs(),
        lazy: async () => {
          const { AdminPanelErrorLogs } = await import(
            '../../pages/account/AdminPanel/sub-pages/AdminPanelErrorLogs'
          );
          return { Component: AdminPanelErrorLogs };
        },
        loader: appLoader([TokenType.Admin]),
      },
      {
        path: AppRoutes.AdminPanelAdvertisement(),
        lazy: async () => {
          const { AdminPanelAdvertisement } = await import(
            '../../pages/account/AdminPanel/sub-pages/AdminPanelAdvertisement'
          );
          return { Component: AdminPanelAdvertisement };
        },
        loader: appLoader([TokenType.Admin]),
      },
      {
        path: AppRoutes.AdminPanelUsers(),
        lazy: async () => {
          const { AdminPanelUsers } = await import(
            '../../pages/account/AdminPanel/sub-pages/AdminPanelUsers'
          );
          return { Component: AdminPanelUsers };
        },
        loader: appLoader([TokenType.Admin]),
      },
      {
        path: AppRoutes.AdminPanelSuggestions(),
        lazy: async () => {
          const { AdminPanelSuggestions } = await import(
            '../../pages/account/AdminPanel/sub-pages/AdminPanelSuggestions'
          );
          return { Component: AdminPanelSuggestions };
        },
        loader: appLoader([TokenType.Admin]),
      },
      {
        path: AppRoutes.AdminPanelQuiz(),
        lazy: async () => {
          const { AdminPanelQuiz } = await import(
            '../../pages/account/AdminPanel/sub-pages/AdminPanelQuiz'
          );
          return { Component: AdminPanelQuiz };
        },
        loader: appLoader([TokenType.Admin]),
      },
    ],
  },
];
