import { TokenType } from 'src/models/authenticate-result';
import { useTryAuthenticate } from 'src/queries/auth-queries';

export const useAuthentication = () => {
  const { authenticateResult, authenticateResultRefetch } = useTryAuthenticate();

  return {
    reloadAuthentication: authenticateResultRefetch,
    isLoggedIn: !!authenticateResult,
    currentUser:
      authenticateResult?.type === TokenType.User || authenticateResult?.type === TokenType.Admin
        ? authenticateResult.entity
        : null,
    currentAnnouncementEmail:
      authenticateResult?.type === TokenType.AnnouncementsManagement
        ? authenticateResult.entity
        : null,
    isAdmin: authenticateResult?.type === TokenType.Admin,
  };
};

export const useLoggedInUser = () => {
  const { currentUser } = useAuthentication();
  return currentUser!;
};
export const useLoggedInAnnouncementEmail = () => {
  const { currentAnnouncementEmail } = useAuthentication();
  return currentAnnouncementEmail!;
};
