import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import axios from 'src/config/axios-config';
import { AuthenticateResult } from 'src/models/authenticate-result';
import { RegularLoginData } from 'src/models/regular-login-data';
import { ExtendedUser } from 'src/models/user';
import { RegisterParams } from 'src/router/app-routes';
import { environment } from 'src/utils/environment-utils';
import {
  genericUseMutationMethods,
  genericUseQueryMethods
} from 'src/utils/types/generic-query-methods';
import { HttpErrorData } from 'src/utils/types/http-error-data';

const baseUrl = `${environment.apiUrl}/Auth`;

export const tryAuthenticateQueryKey = 'TryAuthenticate';
export const tryAuthenticateQueryFn = () => axios.get(`${baseUrl}/TryAuthenticate`);
export const useTryAuthenticate = (
  options?: UseQueryOptions<AuthenticateResult | null, AxiosError<HttpErrorData>>
) => {
  const query = useQuery({
    queryKey: tryAuthenticateQueryKey,
    queryFn: tryAuthenticateQueryFn,
    staleTime: Infinity,
    ...options,
  });

  return genericUseQueryMethods('authenticateResult', query);
};

export const useRegularRegister = (
  options?: UseMutationOptions<
    ExtendedUser | null,
    AxiosError<HttpErrorData>,
    ExtendedUser & RegisterParams
  >
) => {
  const mutation = useMutation({
    mutationFn: (data) => axios.post(`${baseUrl}/RegularRegister`, data),
    ...options,
  });

  return genericUseMutationMethods('regularRegister', mutation);
};

export const useResendActivationLink = (
  options?: UseMutationOptions<void, AxiosError<HttpErrorData>, ExtendedUser['email']>
) => {
  const mutation = useMutation({
    mutationFn: (email) => {
      const params = { email };
      return axios.post(`${baseUrl}/ResendActivationLink`, null, { params });
    },
    ...options,
  });

  return genericUseMutationMethods('resendActivationLink', mutation);
};

export const useRegularLogin = (
  options?: UseMutationOptions<ExtendedUser, AxiosError<HttpErrorData>, RegularLoginData>
) => {
  const mutation = useMutation({
    mutationFn: (data) => axios.post(`${baseUrl}/RegularLogin`, data),
    ...options,
  });

  return genericUseMutationMethods('regularLogin', mutation);
};

export const useLogout = (options?: UseMutationOptions<void, AxiosError<HttpErrorData>, void>) => {
  const mutation = useMutation({
    mutationFn: () => axios.post(`${baseUrl}/Logout`),
    ...options,
  });

  return genericUseMutationMethods('logout', mutation);
};

export const useSendForgotPasswordEmail = (
  options?: UseMutationOptions<unknown, AxiosError<HttpErrorData>, ExtendedUser['email']>
) => {
  const mutation = useMutation({
    mutationFn: (email) => {
      const params = { email };
      return axios.post(`${baseUrl}/SendForgotPasswordEmail`, null, { params });
    },
    ...options,
  });

  return genericUseMutationMethods('sendForgotPasswordEmail', mutation);
};

export const useResetPassword = (
  options?: UseMutationOptions<
    void,
    AxiosError<HttpErrorData>,
    { key: string; newPassword: string }
  >
) => {
  const mutation = useMutation({
    mutationFn: (data) => axios.patch(`${baseUrl}/ResetPassword`, data),
    ...options,
  });

  return genericUseMutationMethods('resetPassword', mutation);
};

export const useRemoveAccount = (
  options?: UseMutationOptions<void, AxiosError<HttpErrorData>, void>
) => {
  const mutation = useMutation({
    mutationFn: () => axios.delete(`${baseUrl}/RemoveAccount`),
    ...options,
  });

  return genericUseMutationMethods('removeAccount', mutation);
};
