import { useSnackbar, VariantType } from 'notistack';
import { ReactNode, useEffect } from 'react';
import { useRouteParams } from 'src/hooks/useRouteParams';

export type UseRouteParamsNotificationProps<TParams extends Record<string, unknown>> = {
  paramName: keyof TParams;
  message: ReactNode;
  variant: VariantType;
};
export const useRouteParamsNotification = <TParams extends Record<string, unknown>>({
  paramName,
  message,
  variant,
}: UseRouteParamsNotificationProps<TParams>) => {
  const { enqueueSnackbar } = useSnackbar();
  const [params, setParams] = useRouteParams<TParams>();

  useEffect(() => {
    if (params[paramName]) {
      enqueueSnackbar({ variant, message });
      setParams({ ...params, [paramName]: undefined }, { replace: true });
    }
  }, [params]);
};
